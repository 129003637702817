.box {
  height: inherit;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.textField {
  border-right: 4px;
  width: 100%;
}

.textField:global(.MuiFormControl-root .MuiInputBase-input) {
  padding: 10px 4px;
  font-size: 14px;
}

.searchIcon,
.sortIcon {
  width: 18px;
  height: 18px;
  user-select: none;
}

.searchIcon {
  margin-right: 8px;
}

.sortIcon {
  cursor: pointer;
}

.optionsContainer {
  padding: 4px 0;
  overflow: auto;
}

.checkbox:global(.MuiFormGroup-root .MuiFormControlLabel-root) {
  padding: 8px 0;
  border-bottom: 1px solid rgba(18, 41, 69, 0.1);
  margin-left: 0;
  display: flex;
}

.checkbox:global(.MuiFormGroup-root .MuiButtonBase-root) {
  margin: 0 8px 0 0;
  padding: 0;
}

.checkbox:global(.MuiFormGroup-root .MuiFormControlLabel-label) {
  flex: 1;
  min-width: 0;
}

.emptyText {
  font-size: 14px;
  text-align: center;
}
