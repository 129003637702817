.header {
  width: 100%;
}

.headerTitle {
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
}

.closeButton {
  padding: 0;
}

.closeButtonIcon {
  width: 24px;
  height: 24px;
}
