.text {
  font-family: 'Inter', sans-serif;
}

.iconBox {
  width: 32px;
  height: 32px;
  text-align: center;
}

.relationItem {
  padding-left: 0;
  list-style-type: circle;
  color: white;
  display: flex;
  align-items: center;
}

.listStyle {
  padding-left: 0;
  list-style-type: circle;
}

.dividerLine {
  border-color: #727272;
}
