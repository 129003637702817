.navLink {
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  text-underline-offset: 8px;
  margin: 8px 16px;
}

.accountLoading {
  margin: 0 24px;
}

@media (min-width: 1200px) {
  .accountLoading {
    margin: unset;
    margin-right: 24px;
  }

  .navLink {
    font-size: 14px;
    font-weight: bold;
    transition: opacity 0.3s;
    position: relative;
    padding: 0;
    margin: 0;
    text-underline-offset: 0;
    text-decoration: none;
  }

  .activeNavLink:after,
  .navLink:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: #f75151;
    left: 0;
    transition: all 0.3s;
  }

  .activeNavLink:after {
    width: 80%;
  }

  .navLink:hover:after {
    width: 80%;
  }
}
