.errorMessage,
.warningMessage {
  font-size: 14px;
  font-weight: 600;
}

.errorMessage {
  color: #f75151;
}

.warningMessage {
  color: #ffa726;
}
