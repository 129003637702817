.header {
  padding: 24px;
}

.title {
  font-size: 21px;
  font-weight: 600;
}

.subTitle {
  font-size: 16px;
  margin: 24px 0;
  text-align: center;
}

.text {
  font-size: 16px;
  font-weight: 600;
  padding: 6px;
}

.btn {
  max-width: 70%;
}

.footer {
  width: 100%;
  gap: 16px;
}
