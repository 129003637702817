.container {
  position: relative;
  overflow: hidden;
  flex: 1;
}

.innerContainer {
  margin: 80px 80px 120px 80px;
}

.title {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0;
  margin-bottom: 80px;
}

.methodologyPageLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: unset;
}

.methodologyPageLayout:global(.MuiBox-root .layoutBody) {
  display: flex;
}

.networkGraphBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 100%;
  top: 100%;
  transform: translate(-50%, -50%) rotate(45deg);
}

@media screen and (max-aspect-ratio: 4/3) and (min-width: 768px) {
  .networkGraphBackground {
    width: 100vw;
    height: 100vw;
  }
}

@media screen and (min-aspect-ratio: 4/3) and (min-width: 768px) {
  .networkGraphBackground {
    width: 100vh;
    height: 100vh;
  }
}

@media (min-width: 1200px) {
  .innerContainer {
    margin: 80px auto 120px auto;
  }
}
