.titleContainer {
  margin: 0;
  padding: 0;
}

.card {
  border-radius: 0;
}

.cardBox {
  position: relative;
  padding: 32px;
}

.networkGraphBackground {
  position: absolute;
  width: 174%;
  height: 174%;
  right: -174%;
  bottom: -174%;
  transform: translate(-50%, -50%) rotateZ(45deg);
}

.quickSearchTitle {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 16px;
}

.subQuickSearchTitle {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 16px;
}

.vesselsCount {
  display: inline;
}

.blurredText {
  filter: blur(10px);
}

.textField {
  width: 100%;
  border-radius: 4px;
  padding: 10px 8px;
}

.textField:global(.MuiFormControl-root .MuiInputBase-root) {
  font-size: 14px;
  padding-right: 2px;
}

.textField:global(.MuiFormControl-root .MuiOutlinedInput-notchedOutline) {
  border: none;
}

.textField:global(.MuiFormControl-root .MuiInputBase-input) {
  padding: 8px 6px;
  font-weight: 600;
}

.endAdornment {
  display: flex;
  align-items: center;
  gap: 16px;
}

.filterBtn,
.clearBtn,
.searchBtn {
  cursor: pointer;
  transition: all 0.3s;
}

.filterBtn {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 4px 8px;
  border-radius: 16px;
  background-color: #f0f1f3;
  border: 1px solid #122945;
}

.filterBtn:hover,
.filterBtn:active {
  background-color: #ffffff;
}

.filterBtnText {
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}

.filterIcon {
  fill: #122945;
  width: 14px;
  height: 14px;
}

.clearBtn {
  font-size: 14px;
}

.searchBtn {
  width: 42px;
  height: 42px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: unset;
}

.searchIcon {
  width: 16px;
  height: 16px;
  fill: #ffffff;
  flex: none;
}

.desktopTextFieldBtn {
  display: none;
}

.mobileTextFieldBtn {
  display: flex;
}

.mobileTextFieldButtonsContainer {
  display: flex;
  padding-top: 12px;
}

@media (min-width: 600px) {
  .desktopTextFieldBtn {
    display: flex;
  }

  .mobileTextFieldBtn {
    display: none;
  }
}

@media (min-width: 768px) {
  .textField:global(.MuiFormControl-root .MuiInputBase-root) {
    font-size: 16px;
    padding-right: 4px;
  }

  .textField:global(.MuiFormControl-root .MuiInputBase-input) {
    padding: 16px 12px;
  }

  .searchBtn {
    width: 64px;
    height: 64px;
  }

  .searchIcon {
    width: 24px;
    height: 24px;
  }

  .filterBtn {
    padding: 8px 16px;
    border-radius: 24px;
  }

  .filterBtnText {
    font-size: 16px;
  }

  .clearBtn {
    font-size: 16px;
  }

  .cardBox {
    padding: 96px 64px;
  }

  .quickSearchTitle {
    font-size: 40px;
  }
}
