.popoverBox {
  background-color: #122945;
  color: #fff;
  padding: 10px;
}

.popoverInnerBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.popoverArrow,
.popoverArrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #122945;
}

.popoverArrow {
  visibility: hidden;
}

.popoverArrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.popover[data-show] {
  display: block;
}

.popover[data-popper-placement^='top'] > .popoverArrow {
  bottom: -4px;
}

.popover[data-popper-placement^='bottom'] > .popoverArrow {
  top: -4px;
}

.popover[data-popper-placement^='left'] > .popoverArrow {
  right: -4px;
}

.popover[data-popper-placement^='right'] > .popoverArrow {
  left: -4px;
}
