:root {
  --node-tooltip-text: none;
  --node-tooltip-color: #fff;
  --node-tooltip-background: #122945;
}

.container {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

.nodeImageContainer {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  position: absolute;
  bottom: calc(100% - 10px);
}

.nodeImage {
  margin: 0 auto;
  display: block;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}

.labelWrapper {
  position: relative;
}

.labelIcon {
  position: absolute;
  top: -4px;
  right: 0;
  transform: translate(50%, -50%);
  background: #ffffff;
  border-radius: 50%;
  border: 1px solid;
  outline: 1px solid #ffffff;
  width: 16px;
  height: 16px;
}

p.label {
  position: relative;
  cursor: pointer;
  overflow: visible;
  text-align: center;
}

.label::after {
  position: absolute;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  top: -4px;
  opacity: 0;
  color: var(--node-tooltip-color);
  background-color: var(--node-tooltip-background);
  left: 50%;
  transform: translate(-50%, -100%);
  box-shadow: 0 0 4px #000;
  max-width: 240px;
  word-break: break-word;
  white-space: initial;
  justify-content: center;
  width: max-content;
  z-index: -1;
  height: 0;
  overflow: hidden;
}

.label::after {
  content: var(--node-tooltip-text);
}

.label:hover::after {
  animation-name: label-tooltip;
  animation-fill-mode: forwards;
  animation-delay: 0.8s;
  animation-duration: 0.2s;
  z-index: 1;
}

.buttonsContainer {
  margin-top: -2px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
}

.button {
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 6px;
  padding: 4px;
  display: flex;
  align-items: center;
  gap: 2px;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0;
  cursor: pointer;
}

.buttonIcon {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:hover {
  filter: brightness(1.8);
}

@keyframes label-tooltip {
  from {
    opacity: 0;
    height: auto;
  }
  10% {
    opacity: 0.1;
  }
  to {
    height: auto;
    opacity: 1;
  }
}
