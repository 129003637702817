@font-face {
  font-family: 'Inter';
  src: url('Inter-Thin.ttf') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Inter';
  src: url('Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Inter';
  src: url('Inter-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Inter';
  src: url('Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url('Inter-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url('Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url('Inter-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Inter';
  src: url('Inter-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Inter';
  src: url('Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Inter';
  src: url('Inter-Black.ttf') format('truetype');
  font-weight: 900;
}
