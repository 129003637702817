.createOrganizationBtn {
  gap: 8px;
  padding: 8px;
  border-bottom: 1px solid;
  cursor: pointer;
}

.createOrganizationText {
  font-size: 14px;
}

.createOrganizationIcon {
  width: 20px;
  height: 20px;
}

.createOrganizationBtn:hover {
  background: #ced3d9; /* them.gray.gray100 */
}

.organizationChip {
  border-radius: 8px;
}
