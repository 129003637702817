.loginCard {
  padding: 3.5rem !important;
}

.loginInput:global(.MuiFormControl-root .MuiInputBase-input):-webkit-autofill,
.loginInput:global(.MuiFormControl-root .MuiInputBase-input):-webkit-autofill:hover,
.loginInput:global(.MuiFormControl-root .MuiInputBase-input):-webkit-autofill:focus,
.loginInput:global(.MuiFormControl-root .MuiInputBase-input):-webkit-autofill:active {
  /* -webkit-text-fill-color: white !important; */
  transition: background-color 5000s ease-in-out 0s;
}

h5.loginTitle {
  font-weight: 600;
}

.loginForm {
  width: 100%;
}

.forgetPassword,
.rememberMe:global(.MuiFormControlLabel-root .MuiTypography-root) {
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
}

.loginAdditionalButtons {
  margin: 32px 0;
}

.loginLabel {
  font-size: 18px;
  font-weight: 600;
}

.linksContainer {
  gap: 12px;
  justify-content: center;
  margin: 16px 0;
}

.link {
  color: #0062d4;
  font-size: 14px;
  text-decoration: none;
  opacity: 0.8;
}

.link:hover {
  opacity: 0.5;
}

.link:active {
  color: #225b7b;
}

.linkDot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.linkDot:first-child {
  display: none;
}

.linksContainer {
  gap: 24px;
}

.link {
  position: relative;
}

.link::after {
  content: '';
  position: absolute;
  right: -14px; /* (.linksContainer gap) / 2 + (.link width) / 2 */
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #d2d2d2;
  top: 50%;
  transform: translate(0, -50%);
}

.link:last-child::after {
  content: none;
}
