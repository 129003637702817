.iuuStatus {
  cursor: pointer;
}

.iuuStatusText {
  padding: 4px;
  border-radius: 4px;
  text-transform: capitalize;
  font-size: inherit;
  display: inline-block;
}

.tooltipTextContainer {
  padding: 4px;
  gap: 4px;
}

.tooltipText,
.tooltipBoldText {
  font-size: 14px;
}

.tooltipBoldText {
  font-weight: 600;
}
