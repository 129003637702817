.header {
  width: 100%;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 24px;
}

.avatar {
  width: 56px;
  height: 56px;
  background-color: transparent;
}

.avatarImage {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.userContainer {
  gap: 20px;
}

.username {
  font-weight: 700;
  font-size: 20px;
}

.role {
  font-size: 14px;
}
