.cancelBtn {
  text-decoration: underline;
}

.autocomplete {
  min-width: 200px;
}

.autocomplete:global(.MuiBox-root .MuiInputBase-input) {
  padding: 10px 12px;
}

.usersCount {
  font-size: 14px;
}

.usersContainer {
  gap: 8px;
}

.usersImportBtn {
  height: 40px;
  gap: 4px;
}

.usersImportBtnIcon,
.editGroupBtnIcon {
  width: 20px;
  height: 20px;
}

.editGroupBtnIcon {
  width: 14px;
  height: 14px;
}

.saveBtn {
  gap: 8px;
}

.usersImportText {
  font-size: 14px;
  font-weight: 600;
}

.section {
  margin-top: 8px;
  margin-bottom: 16px;
}

.inviteUserBtn {
  gap: 8px;
  padding: 8px;
  border-bottom: 1px solid;
  cursor: pointer;
}

.inviteUserBtn:hover {
  background: #ced3d9; /* them.gray.gray100 */
}

.inviteUserIcon {
  width: 20px;
  height: 20px;
}

.userOption {
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
}
